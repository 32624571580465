import { ComputedRef, computed } from 'vue';

type TimeDifference = {
  days: string;
  hours: string;
  mins: string;
};

export const useCountdown = (
  target: string
): { timeDifference: ComputedRef<TimeDifference> } => {
  const timeDifference = computed(() => {
    const ONE_HOUR_IN_MILLISECONDS = 1000 * 60 * 60;

    const currentDate = new Date();
    const targetDate = new Date(target);
    const difference = targetDate.getTime() - currentDate.getTime();

    const days = Math.floor(difference / (ONE_HOUR_IN_MILLISECONDS * 24))
      .toString()
      .padStart(2, '0');
    const hours = Math.floor(
      (difference % (ONE_HOUR_IN_MILLISECONDS * 24)) / ONE_HOUR_IN_MILLISECONDS
    )
      .toString()
      .padStart(2, '0');
    const mins = Math.floor(
      (difference % ONE_HOUR_IN_MILLISECONDS) / (1000 * 60)
    )
      .toString()
      .padStart(2, '0');

    return {
      days,
      hours,
      mins,
    };
  });

  return { timeDifference };
};
